import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { styles } from "../../Shared/Styles/NavStyles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Home from "@material-ui/icons/Home";
import EmployerIcon from "@material-ui/icons/LocationCity";
import MedicareIcon from "@material-ui/icons/LocalHospital";
import PrintIcon from "@material-ui/icons/Print";
import TerminationIcon from "@material-ui/icons/Cancel";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import AssignmentIcon from "@material-ui/icons/Assignment";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";

import BrokerIcon from "@material-ui/icons/People";

class NavList extends React.Component {
  goHome = () => {
    this.props.history.push("/mewa-admin/dashboard");
  };

  goMedicare = () => {
    this.props.history.push("/mewa-admin/medicare");
  };

  goMedicareSubmissions = () => {
    this.props.history.push("/mewa-admin/medicare-submissions");
  };

  goEmployers = () => {
    this.props.history.push("/mewa-admin/employers");
  };

  goTerminations = () => {
    this.props.history.push("/mewa-admin/termination");
  };

  goContacts = () => {
    this.props.history.push("/mewa-admin/brokers");
  };

  goConfirmationRequests = () => {
    this.props.history.push("/mewa-admin/confirmations");
  };

  goChangeRequests = () => {
    this.props.history.push("/mewa-admin/change-requests");
  };

  goArcForm = () => {
    this.props.history.push("/mewa-admin/arc");
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.navList}>
        <List
          component="nav"
          subheader={
            <ListSubheader component="div">
              <img
                src={this.props.theme.navlist_logo}
                className={classes.headerlogo}
                style={{ width: 130, marginTop: 10 }}
                alt="Reform Health"
              />
            </ListSubheader>
          }
        >
          <ListItem button onClick={this.goHome}>
            <ListItemIcon className={classes.listItem}>
              <Home />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem button onClick={this.goEmployers}>
            <ListItemIcon className={classes.listItem}>
              <EmployerIcon />
            </ListItemIcon>
            <ListItemText primary="Employers" />
          </ListItem>

          <ListItem button onClick={this.goTerminations}>
            <ListItemIcon className={classes.listItem}>
              <TerminationIcon />
            </ListItemIcon>
            <ListItemText primary="Termination Records" />
          </ListItem>

          <ListItem button onClick={this.goContacts}>
            <ListItemIcon className={classes.listItem}>
              <BrokerIcon />
            </ListItemIcon>
            <ListItemText primary="Brokers" />
          </ListItem>

          <ListItem button onClick={this.goArcForm}>
            <ListItemIcon className={classes.listItem}>
              <AssignmentIcon />
            </ListItemIcon>
            <ListItemText primary="ARC Forms" />
          </ListItem>

          <ListSubheader style={{ color: "black" }}>Medicare</ListSubheader>
          <ListItem button onClick={this.goMedicare}>
            <ListItemIcon className={classes.listItem}>
              <MedicareIcon />
            </ListItemIcon>
            <ListItemText primary="Medicare Records" />
          </ListItem>

          <ListItem button onClick={this.goMedicareSubmissions}>
            <ListItemIcon className={classes.listItem}>
              <PrintIcon />
            </ListItemIcon>
            <ListItemText primary="Medicare Submissions" />
          </ListItem>
        </List>
      </div>
    );
  }
}

export default withStyles(styles)(NavList);
